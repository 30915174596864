/* --------------------- GLOBAL ---------------------------- */

body{
  font-family:  Calibri, Helvetica, Arial, Verdana, 'Times New Roman', Times, serif;
}

p{
  font-size: 18px;
  margin: 39px 5px;
  line-height: 23px;
}

li{
  font-size: 18px;
  margin: 5px;
}

*:focus{
  color: black;
  text-decoration: underline;
  border: solid black 4px;
}

button:focus, input:focus{
  border: solid rgb(87, 57, 22) 4px;
  text-decoration: none;
}

label:focus{
  background-color: rgb(173, 173, 173);
}


a:focus{
  border: solid black 5px;
}

.nav-selected:focus{
  border: solid yellow 5px;

}

button:focus{
  background-color: yellow;
}

.margin-top{
  margin-top: 70px ;
}

.component-section{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.h1-title{
margin-bottom: 10px;
}

.h2-title{
  margin-top: 5px;
  font-size: 20px;
  font-weight: 300;
  width: 60vw;
  max-width: 630px;
}

#navbar{
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100vw;
  background-color: gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link-btn{
  border: solid 1px black;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 40%;
  background-color: white;

}

.link-text{
  font-size: 16px;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.line{
  width: 80vw;
  height: 1px;
  background-color: gray;
  margin: 50px;
}

.checkbox-h1{
  margin: 2px;
}


.nav-selected{
  background-color: black;
  border: solid white 2px;
  color: white;
}

.nav-not-selected{
  background-color: white;
  border: solid black 1px;
  color: black;
}

.button{
  background-color: #00FF0A;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}


/* --------------------------------- TODO SECTION ----------------------------------- */

.new-todo-wrapper{
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 30px 5px 5px 5px;
}

#todo-input{
  height: 25px;
}

.new-todo-wrapper label{
  font-weight: 600;
}

#add-btn{
  background-color: #00FF0A;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.task-box{
  padding: 4px 30px 4px 4px;
  display: flex;
  align-items: center;
  border: solid black 2px;
  margin: 10px;
  cursor: pointer;
}

.task-box input{
  height: 30px;
  width: 30px;
  margin-right: 30px;
}

/* --------------------------------- ABOUT ----------------------------- */


.about-article{
  width: 60vw;
  padding: 50px;
  border-radius: 10px;
  background-color: #FFFEDD;
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 630px;
}

.about-article li{
  text-align: start;
}

.about-article img {
  width: 90%;
}

iframe{
  margin: 30px 0;
}

#captions-wrapper{
  height: 145px;
  border: solid 1px black;
  background-color: #F7F7F7;
  overflow: auto;
  padding: 40px;
  text-align: center;
  resize: vertical;
}

#captions-wrapper p{
  margin: 5px;
  text-align: start;
}

#captions-wrapper h2{
  font-weight: 500;
  margin: 0 0 30px 0;
}

iframe{
  width: 400px;
  height: 225px;
}

#form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  row-gap: 20px;
  max-width: 480px ;
  margin: 60px;
}

#form input{
  height: 25px;
  width: 100%;
}

.pair-wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-div{
display: flex;
flex-direction: column;
align-items: start;
width: 45%;
}

.message-form-div{
  width: 100%;
}

#message{
  width: 100%;
  text-decoration: none;
}

.error-message{
left: 10px;
align-self: center;
border: solid black 1px;
background-color: #ed0000;
color: white;
padding: 3px 8px;
margin-top: 2px; 
display: none;
}

.drag-resize{
  align-self: end;
  margin-top: 3px;
}



/* --------------------------------- MODAL ----------------------------- */

#set-component{
  display: none;
}

.modal-wrapper{
 justify-content: center;
 align-items: center;
 width: 100vw;
 height: 100vh;
 position: fixed;
 background-color: rgba(0, 0, 0, .4);
}

.modal-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 10vw;
  background-color: antiquewhite;
  border-radius: 5px;

}

.modal-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content button{
    background-color: #00FF0A;
    margin: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}


/* --------------------------------- FÖR TELEFON ----------------------------- */

@media screen and (max-width: 480px) {
  .h2-title, 
  .about-article,
  .about-article img{
    width: 80vw;
  }

  .about-article{
    padding: 3vw;
  }

  #captions-wrapper{
    padding: 10px;
  }

  iframe{
    width: 100%;
  }

  .button, #add-btn{
    font-size: 16px;
    padding: 8px 13px;
  }

  #navbar{
    height: 60px;
  }

  .link-btn {
    padding: 9px;
  }

}